<template>
  <div>
    <SettingsBanner></SettingsBanner>

    <b-container v-if="loaded" class="mb-5">
      <SettingsTitle
        v-bind:title="'Artist Profile - Edit Tags'"
      ></SettingsTitle>

      <!--<b-row class="row mb-5">
        <b-col >
            <label class="sr-only" for="input-search">Search</label>
            <b-input-group class="mb-2 mt-4 mr-sm-2 mb-sm-0">
            <b-input-group-prepend
                class="p-2 search-input"
                style="
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                "
                :class="{ 'mobile-search-bar': isMobile() }"
            >
                <img src="../../../assets/search-icon.svg" />
            </b-input-group-prepend>
            <b-form-input
                id="input-search"
                class="search-input text-light font-weight-light"
                style="
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                "
                :class="{ 'mobile-search-bar': isMobile() }"
                placeholder="Search"
                v-model="search_param"
                v-on:input="search_change()"
            ></b-form-input>
            </b-input-group>
        </b-col>
      </b-row>-->

      <b-row class="mt-3">
        <SectionHeading v-bind:name="'SELECTED'"></SectionHeading>

        <b-col cols="3">
          <p class="text-light font-weight-light">
            Tags
          </p>
        </b-col>
        <b-col cols="9">
          <b-row>
            <b-col>
              <div
                v-for="(genre, index) in selected_genres"
                :key="index"
                class="d-inline"
              >
                <ChoiceButton
                  :text="genre.value"
                  :selected="'true'"
                  style="margin-bottom:10px; background-color: #4A4A4A;"
                  :selected_item="genre"
                  class="px-4"
                ></ChoiceButton>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <SectionHeading v-bind:name="'GENRES'"></SectionHeading>

        <b-col cols="3">
          <p class="text-light font-weight-light">
            Choose
          </p>
        </b-col>
        <b-col cols="9">
          <b-row style="height:300px" class="wrapper">
            <b-col style="height:300px; overflow-y: scroll;">
              <ChoiceButton
                v-for="genre in genres"
                :key="genre.id"
                :text="genre.value"
                :id="genre.id"
                :name="genre.tag"
                :data="genre"
                @selected="handle_selected"
                v-bind:class="{ isSelected: genre.selected, notSelected: !genre.selected }"
              ></ChoiceButton>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-5 mb-4">
        <b-col cols="12" class="text-center mb-4">
          <b-button class="done-btn mr-2" @click="clear">
            Cancel
          </b-button>
          <b-button class="done-btn ml-2" style="width:250px;" @click="save">
            Save and Return to Artist Profile
          </b-button>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import SettingsBanner from "../../../components/settings/Banner";
import SettingsTitle from "../../../components/settings/Title.vue";
import SectionHeading from "../../../components/settings/SectionHeading.vue";
import ChoiceButton from "../../../components/settings/choice-button-auto.vue";

export default {
  name: "EditUserProfileGenres",
  components: {
    SettingsBanner,
    SettingsTitle,
    SectionHeading,
    ChoiceButton,
  },
  data() {
    return {
      loaded: false,
      genres: [],
      all_genres: [],
      selected_genres: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("Account", ["get_tags", "set_tags"]),
    clear() {
      this.selected_genres = [];
    },
    removeGenre(index) {
      this.selected_genres.splice(index, 1);
    },
    handle_selected(id, selected, data) {
      var gindex = this.genres.indexOf(data);

      var selected_ = false;

      if (!this.selected_genres.includes(data)) {
        data.selected = true;
        this.selected_genres.push(data);
        selected_ = true;
      } else {
        var index = this.selected_genres.indexOf(data);
        data.selected = false;
        this.removeGenre(index);
        selected_ = false
      }

      this.genres[gindex].selected = selected_;
    },
    checkGenre(genre) {
      if (this.selected_genres.includes(genre)) {
        return true
      } else {
        return false
      }
    },
    save() {
      var data = {};

      var tags = [];

      this.selected_genres.forEach(function(tag) {
        tags.push(tag.value);
      });

      data["tags"] = tags;

      this.set_tags(data)
        .then((res) => {
          res;
          this.$router.replace({ name: "EditProfile" });
        })
        .catch((error) => {
          error;
          //console.log(error);
          //this.$router.replace("/error");
        });
    },
  },
  mounted() {
    this.get_tags()
      .then((res) => {
        this.genres = res.tags;

        var tags = [];
        this.genres.forEach(function(tag) {
          if (tag.selected) {
            tags.push(tag);
          }
        });

        this.selected_genres = tags;

        this.loaded = true;
      })
      .catch((error) => {
        error;
        //this.$router.replace("/error");
      });
  },
  beforeMount() {},
};
</script>

<style>
.isSelected {
  margin-bottom: 10px;
  background-color: #4a4a4a !important;
}
.notSelected {
  margin-bottom: 10px;
  background-color: transparent !important;
}

.select-input {
  background: #0e0e0f url("../../../assets/select-box-arrow.svg") no-repeat
    right 0.75rem center/20px 15px !important;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
  text-align: left !important;
  text-align-last: left !important;
}

.text-input {
  background-color: #0e0e0f;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.form-control:focus {
  background-color: #0e0e0f;
  color: #9b9b9b;
}

.submit-btn {
  border: solid white 1px;
  background-color: transparent;
  width: 185px;
  height: 37px;
}

.wrapper {
  /* min-width: 100%; */
  width: 100%;
  height: 200px;
  /* border: 1px solid #ddd; */
  border-radius: 0;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.wrapper .item {
  min-width: 150px;
  height: 200px;
  /* line-height: 20px; */
  text-align: center;
}

.done-btn {
  border: solid white 1px;
  background-color: transparent;
  width: 129px;
  height: 37px;
}

.done-btn:hover {
  border: solid white 1px;
  background-color: transparent;
}

.wrapper {
  min-width: 100%;
  border-radius: 0;
  display: flex;
  overflow-x: auto;
}
</style>
